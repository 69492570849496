import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bxs-bar-chart-alt-2',
        link: '/dashboard',
    },
    {
        id: 3,
        label: 'MENUITEMS.COMMANDES.TEXT',
        icon: 'bx-receipt',
        link: '/commandes',
    },
    {
        id: 5,
        label: 'MENUITEMS.CLIENTS.TEXT',
        icon: 'bxs-user-detail',
        link: '/clients',
    },
    {
        id: 6,
        label: 'MENUITEMS.REVENUES.TEXT',
        icon: 'bx bx-money',
        link: '/revenus',
    },
    {
        id: 7,
        label: 'MENUITEMS.PERSONNEL.TEXT',
        icon: 'bx-group',
        link: '/personnel',
    },
    {
        id: 8,
        label: 'MENUITEMS.UTILISATEURS.TEXT',
        icon: 'bx-group',
        link: '/users',
    },
    {
        id: 9,
        label: 'MENUITEMS.STATISTIQUES.TEXT',
        icon: 'bx-line-chart',
        link: '/statistiques',
    },
    {
        id: 12,
        label: 'MENUITEMS.PARAMETRAGE.TEXT',
        isTitle: true
    },
    {
        id: 13,
        label: 'MENUITEMS.FICHEMESURE.TEXT',
        icon: 'bx-tone',
        link: '/fiches-mesures',
    },

    {
        id: 14,
        label: 'MENUITEMS.PARAMETRAGE.TEXT',
        icon: 'bx-cog',
        link: '/parametrage',
    },
    {
        id: 15,
        label: 'MENUITEMS.ABONNEMENT.TEXT',
        icon: 'bxs-dollar-circle',
        link: '/abonnement',
    },
];


export const MENU_ADMIN: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bxs-bar-chart-alt-2',
        link: '/dashboard',
    },
    {
        id: 3,
        label: 'MENUITEMS.MAISONCOUTURE.TEXT',
        icon: 'bx-building-house',
        link: '/maisons-couture',
    },
    {
        id: 4,
        label: 'MENUITEMS.UTILISATEURS.TEXT',
        icon: 'bx-group',
        link: '/users',
    },
    {
        id: 7,
        label: 'MENUITEMS.PARAMETRAGE.TEXT',
        isTitle: true
    },
    {
        id: 6,
        label: 'MENUITEMS.ROLES.TEXT',
        icon: 'bx-tone',
        link: '/roles',
    },
    {
        id: 8,
        label: 'MENUITEMS.FICHEMESURE.TEXT',
        icon: 'bx-tone',
        link: '/fiches-mesures',
    },
    {
        id: 9,
        label: 'MENUITEMS.ABONNEMENT.TEXT',
        icon: 'bxs-dollar-circle',
        link: '/abonnement',
    },
];

