import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user.model';

@Pipe({
    name: 'initial'
})

export class GetInitialPipe implements PipeTransform {
  transform(user: User) {
    let nameString = user.name ? user.name : user.email; 
    if(!nameString) return '';

    return nameString.split(' ')[0].charAt(0);
  }
}