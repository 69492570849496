import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Commande } from 'src/app/core/models/commande.model';

import { CommandeService } from 'src/app/core/services/entities/commande.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import Swal from 'sweetalert2';
import { PaiementCommandeModalComponent } from '../paiement-commande-modal/paiement-commande-modal.component';
import { ParametrageService } from 'src/app/core/services/entities/parametrage.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { LivraisonWithPaiementComponent } from '../livraison-with-paiement/livraison-with-paiement.component';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-detail-commande-modal',
  templateUrl: './detail-commande-modal.component.html',
  styleUrls: ['./detail-commande-modal.component.scss']
})
export class DetailCommandeModalComponent implements OnInit {

  selectedCommande: Commande;

  @Input() commande: Commande;

  @Output()
  statusChange : EventEmitter<string> = new EventEmitter<string>();

  @Output()
  retirerEvent : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  deleteEvent : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  commandeChange : EventEmitter<Commande> = new EventEmitter<Commande>();

  isRoleAtelier;
  isAdmin;
  isBoutique;
  submit = false;

  monnaie;


  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private loader: LoaderService
    , private service: CommandeService, private parametrageService: ParametrageService
    , private storage: AngularFireStorage, private lightbox: Lightbox) { }

  ngOnInit(): void {

    console.log('yo details '  );
    console.log('commande: ' + this.commande );
    let role = sessionStorage.getItem('role');
    this.isRoleAtelier = role == 'Atelier';
    this.isAdmin = role == 'Admin Couturier';
    this.isBoutique = role == 'Boutique';
    this.monnaie = this.parametrageService.getCurrency();


  }

  confirmDelete(id: number) {
    Swal.fire({
      title: 'Etes vous sûre?',
      text: 'Vous êtes sur le point de supprimer cet commande',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui, supprimer!'
    }).then(result => {
      if (result.value) {
        this.delete(id);
        
      }
    });
  }

  delete(id: number){
    this.loader.show();
    let photos = this.getImagesFromCommande(this.commande);
      this.service.delete(id).subscribe(
        () => {
            this.retirerEvent.emit(id);
            this.deleteEvent.emit(id);
            this.loader.hide();
            this.activeModal.dismiss();
            Swal.fire('Supprimé!', 'La commande a été supprimée.', 'success');
            photos.forEach(image => {
              this.storage.refFromURL(image).delete();
            });
            

        },
        error => {
          this.loader.hide();
        });
  }

  openImage(url: string): void {
    // open lightbox

    const images = [];

    const src = url;
      const caption = 'Image Tissu';
      const thumb = url;
      const album = {
        src,
        caption,
        thumb
      };

      images.push(album);
    
    this.lightbox.open(images, 0, {
      showZoom: true
    });
  }

  getImagesFromCommande(commandeParam) :  string[]{

    let result = [];

    if(!commandeParam) return result;

    for(var couture  of commandeParam?.coutures){
      if(couture.photoTissu) result.push(couture.photoTissu);
    }

    return result;
  }

  confirmTerminee(id: number, ref: string) {
    Swal.fire({
      title: 'Etes vous sûre?',
      text: 'Vous êtes sur le point de déclarer la commande ' + ref + ' comme terminée',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui, terminer!',
      cancelButtonText: 'Annuler'
    }).then(result => {
      if (result.value) {
        this.terminer(id);
        
      }
    });
  }

  terminer(id: number){

    this.loader.show();
      this.service.terminer(id).subscribe(
        (data) => {
            this.commande = data;
            this.retirerEvent.emit(id);
            this.commandeChange.emit(data);
            this.statusChange.emit("terminer");
            this.loader.hide();
            Swal.fire('Ok!', 'La commande ' + data.reference + ' est au statut terminé.', 'success');

        },
        error => {
          
        });
  }

  confirmLivree(id: number, ref: string) {
    Swal.fire({
      title: 'Etes vous sûre?',
      text: 'Vous êtes sur le point de déclarer la commande ' + ref + ' comme livrée',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui, livrer!',
      cancelButtonText: 'Annuler'
    }).then(result => {
      if (result.value) {
        this.livrer(id);
        
      }
    });
  }


  livrer(id: number){

      this.loader.show();
      this.service.livrer(id).subscribe(
        data => {
            this.commande = data;
            this.retirerEvent.emit(id);
            this.statusChange.emit("livrer");
            this.commandeChange.emit(data);
            this.loader.hide();
            Swal.fire('Ok!', 'La commande ' + data.reference + ' est au statut livré.', 'success');
        },
        error => {
          
        });
  }

  openLivraisonModal() {

    const modalRef = this.modalService.open(LivraisonWithPaiementComponent, { size: 'md', centered: true });
		modalRef.componentInstance.selectedCommande = this.commande;
    modalRef.componentInstance.statusChange.subscribe(val => this.statusChange.emit(val));
    modalRef.componentInstance.retirerEvent.subscribe(id => this.retirerEvent.emit(id));
    modalRef.componentInstance.commandeChange.subscribe(c => {
      this.commande = c;
      this.commandeChange.emit(c);
    });

  }

  openPaiementModal() {

    const modalRef = this.modalService.open(PaiementCommandeModalComponent, { size: 'md', centered: true });
		modalRef.componentInstance.selectedCommande = this.commande;
    //modalRef.componentInstance.statusChange.subscribe(val => this.statusChange.emit(val));
    //modalRef.componentInstance.retirerEvent.subscribe(id => this.retirerEvent.emit(id));
    modalRef.componentInstance.commandeChange.subscribe(c => {
      this.commande = c;
      this.commandeChange.emit(c);
    });

  }
}
