const pricingData = [
    {
        name: 'Tailleur',
        text: 'Couturier individuel',
        icon: 'bx-user',
        price: 3000,
        features: ['1 utilisateur','Notification SMS','Statistique Finances', '-', '-' ]
    },
    {
        name: 'Atelier',
        text: '5 utilisateurs',
        icon: 'bx-group',
        price: 5000,
        features: ['3 utilisateurs','Notification SMS','Statistique Finances','-', '-' ]
    },
    {
        name: 'Maison de couture',
        text: 'Utilisateurs illimites',
        icon: 'bx-building',
        price: 35000,
        features: ['10 utilisateurs', 'Notification SMS','Statistique Finances','Gestion Personnel', '-']
    },
    {
        name: 'Entreprise',
        text: 'Application sur mesure',
        icon: 'bx-buildings',
        price: 50000,
        features: ['Utilisateurs illimites', 'Notification SMS','Statistique Finances','Gestion Personnel', 'Personnalisation' ]
    },
];

export { pricingData };

