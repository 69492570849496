import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/auth.service";


@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUser();

    if(currentUser){

      const authReq = request.clone({
        headers: new HttpHeaders({
            'x-tenant': currentUser.tenant
        })
      });
    
    
      return next.handle(authReq);

    }else{
      return next.handle(request);
    }

    //return next.handle(request);
   
  }
}