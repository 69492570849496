import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Commande } from 'src/app/core/models/commande.model';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { CommandeService } from 'src/app/core/services/entities/commande.service';
import { DetailCommandeModalComponent } from 'src/app/pages/commandes/modal/detail-commande-modal/detail-commande-modal.component';


@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {


  @Output()
  statusChange : EventEmitter<string> = new EventEmitter<string>();

  term: string;

  isRoleAtelier = false;
  isAdmin=false;
  isBoutique;


  @Input() transactions: Array<Commande>;



  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    let role = sessionStorage.getItem('role');
    this.isRoleAtelier = role == 'Atelier';
    this.isAdmin = role == 'Admin Couturier';
    this.isBoutique = role == 'Boutique';
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }


  /**
   * Open modal
   * @param content modal content
   */
  openDetailCommandeModal(data) {

    const modalRef = this.modalService.open(DetailCommandeModalComponent, { size: 'xl', centered: true });
		modalRef.componentInstance.commande = data;
    modalRef.componentInstance.statusChange.subscribe(val => this.statusChange.emit(val));
    modalRef.componentInstance.retirerEvent.subscribe(id => this.retirerElement(id));
  
  }

  transform(statut : string) {

    if(statut == 'A_TRAITER') return 'A traiter';
    if(statut == 'EN_COUTURE') return 'En couture';
    if(statut == 'TERMINEE') return 'Terminée';
    if(statut == 'LIVREE') return 'Livrée';
    if(statut == 'RETOUR') return 'Retournée';

    
    if(statut == 'A_PAYER') return 'A payer';
    if(statut == 'PAYEE') return 'Payé';
    if(statut == 'ACOMPTE') return 'Avance';

    if(statut == 'ORANGE_MONEY') return 'Orange Money';
    if(statut == 'WAVE') return 'Wave';
    if(statut == 'CHEQUE') return 'Chèque'
    if(statut == 'CARTE_BANCAIRE') return 'Carte bancaire';
    if(statut == 'ESPECES') return 'Espèces';

    return statut;
  }


  retirerElement(id){
    this.transactions = this.transactions.filter(
      (c: Commande) => c.id != id
    );
  }

}
