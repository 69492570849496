<div *ngIf="false" id="preloader">
  <div id="status">
    <div class="spinner-chase">
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="cssload-container">

  <div *ngIf="false" class="cssload-speeding-wheel"></div>

  <div id="status">
    <div class="spinner-chase">
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
    </div>
  </div>
</div>

