import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Abonnement } from "../../models/abonnement.model";

@Injectable({ providedIn: 'root' })
export class AbonnementService {

    constructor(private http: HttpClient) { }

    getStatut() {
        return this.http.get<Abonnement>(environment.BASE_URL + `/api/abonnement/statut`);
    }

    getInvoiceUrl() {
        return this.http.get<any>(environment.BASE_URL + `/api/abonnement/invoice-url`);
    }

    checkPaiementResult(token:string) {
        return this.http.get<any>(environment.BASE_URL + `/api/abonnement/paiement-result/${token}`);
    }

    reabonnement(slug:string) {
        return this.http.get<any>(environment.BASE_URL + `/api/abonnement/reabonnement/${slug}`);
    }

    generateQrCode() {
        return this.http.get<any>(environment.BASE_URL + `/api/abonnement/omqrcode`);
    }

    onestep(body): any {
        return this.http.post(environment.BASE_URL + `/api/abonnement/onestep-pay`, body);
    }

    userExempte() : boolean{

        let role = sessionStorage.getItem('role');
        let tenant = sessionStorage.getItem('tenant');

        if(role == "Admim") return true;
        else {
            if(tenant == 'colle-sow-ardo' || tenant == 'kc-dakar-' || tenant == 'ndigal-couture' ) return true;
            else return false;
        }
    }

    touchpay(body: any) {
        return this.http.post<any>(environment.BASE_URL + `/api/abonnement/paiement-success`, body);
    }

    /*
    calltouchpay(){
        sendPaymentInfos(new Date().getTime(),
                         'GIDEF23708','KyuqqQFFLNqbVCgnFyd9cmzatOLqufTo5hnCjqoLXAq8i36ykT',
                         'portail.smartcouture.app',  '',
                         '', 40,
                         'Dakar', '','', '',  '');
    }
    */

}