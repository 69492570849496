import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private authfackservice: AuthfakeauthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
            const currentUser = this.authenticationService.currentUser();
            if (currentUser && currentUser.token) {
                const authReq  = request.clone({

                    headers: new HttpHeaders({
                        'Authorization':  `Bearer ${currentUser.token}`,
                        'Access-Control-Allow-Origin': '*',
                        'x-tenant': currentUser.tenant,
                    })
                });

                return next.handle(authReq);
            } else{
                return next.handle(request);
            }
    }
}
