import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'statut'
})

export class StatutCommandePipe implements PipeTransform {
  transform(statut : string) {

    if(statut == 'A_TRAITER') return 'A traiter';
    if(statut == 'EN_COUTURE') return 'En couture';
    if(statut == 'TERMINEE') return 'Terminée';
    if(statut == 'LIVREE') return 'Livrée';
    if(statut == 'RETOUR') return 'Retournée';

    if(statut == 'A_PAYER') return 'A payer';
    if(statut == 'PAYEE') return 'Payé';
    if(statut == 'ACOMPTE') return 'Avance';

    if(statut == 'ORANGE_MONEY') return 'Orange Money';
    if(statut == 'WAVE') return 'Wave';
    if(statut == 'CHEQUE') return 'Chèque'
    if(statut == 'CARTE_BANCAIRE') return 'Carte bancaire';
    if(statut == 'ESPECES') return 'Espèces';

    return statut;
  }
}