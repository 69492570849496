<nav class="navbar navbar-expand-lg navigation fixed-top sticky" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <a class="navbar-logo" routerLink="/">
            <img src="assets/images/logo-light-3.png" alt="" height="42" class="logo logo-dark">
            <img src="assets/images/logo-light-3.png" alt="" height="42" class="logo logo-light">
        </a>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
            data-toggle="collapse" data-target="#topnav-menu-content" (click)="toggleMenu()">
            <i class="fa fa-fw fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="topnav-menu-content">
            <ul class="navbar-nav ms-auto" id="topnav-menu">
                <li class="nav-item">
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'home'"
                        [ngClass]="{'active':currentSection === 'home'}">Home</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'about'"
                        [ngClass]="{'active':currentSection === 'about'}">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'features'"
                        [ngxScrollToOffset]="5" [ngClass]="{'active':currentSection === 'features'}">Features</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'news'" [ngxScrollToOffset]="5"
                        [ngClass]="{'active':currentSection === 'news'}">News</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'pricing'"
                        [ngClass]="{'active':currentSection === 'pricing'}">Tarifs</a>
                </li> 

            </ul>

            <div class="my-2 ms-lg-2">
                <a routerLink="/account/login" class="btn btn-info w-xs">Se connecter</a>
                
            </div>

            <div class="my-2 ms-lg-2">
                
                <a routerLink="/account/signup" class="btn btn-success w-xs">S'inscrire</a>
            </div>
        </div>
    </div>
</nav>

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <!-- hero section start -->
    <section class="section hero-section bg-ico-hero" id="home">
        <div class="bg-overlay bg-primary"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="text-white-50">
                        <h1 class="text-white fw-semibold mb-3 hero-title">Gérez plus facilement votre maison de couture</h1>
                        <p class="font-size-14">L'application pour une meilleure gestion de vos commandes et fiches de mesures</p>

                        <div class="button-items mt-4">
                            <a routerLink="/account/signup" class="btn btn-success">S'inscrire</a>
                            <a href="javascript: void(0);" [ngxScrollTo]="'features'" class="btn btn-light">En savoir plus</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-8 col-sm-10 ms-lg-auto">
                    <div>
                        <img src="assets/images/landing/tailleur-mesure.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                    
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- hero section end -->



    <!-- about section start -->
    <section class="section pt-5 bg-white" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h4>SmartCouture</h4>
                        <div class="small-title">Le Digital au service de la Couture</div>
                        
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5">

                    <div class="text-muted">
                        <h4>Gagnez du temps et de la precision</h4>
                        <p>Ne perdez plus les mesures de vos clients. Sauvegardez et retrouvez les facilement dans l'application</p>
                        <p class="mb-4">Enregistrez et suivez vos commandes clients, déclarer les comme terminées, le client est notifié automatiquement</p>

                        <div class="button-items">
                            <a  routerLink="/account/signup" class="btn btn-success">S'inscrire</a>
                            
                        </div>

                        <div class="row mt-4">
                            <div>
                                <img src="assets/images/landing/designer.jpg" alt="" class="img-fluid mx-auto d-block">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 ms-auto">
                    <div class="mt-4 mt-lg-0">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="card border">
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <i class="mdi mdi-account-details h2 text-success"></i>
                                        </div>
                                        <h5>Mesures clients</h5>
                                        <p class="text-muted mb-0">Stockées en ligne, ne les perdez plus</p>

                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="card border mt-lg-5">
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <i class="mdi mdi-wallet-outline h2 text-success"></i>
                                        </div>
                                        <h5>Commandes de couture</h5>
                                        <p class="text-muted mb-0">Terminées a temps, avec un meilleur suivi</p>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <hr class="my-5">


        </div>
        <!-- end container -->
    </section>
    <!-- about section end -->

    <!-- Features start -->
    <section class="section" id="features">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Fonctionnalites</div>
                        <h4>Principales fonctionnalites de SmartCouture</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center pt-4">
                <div class="col-md-6 col-sm-8">
                    <div>
                        <img src="assets/images/crypto/features-img/img-1.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
                <div class="col-md-5 ms-auto">
                    <div class="mt-4 mt-md-auto">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">01</div>
                            <h4 class="mb-0">Numérisez vos fiches mesures</h4>
                        </div>
                        <p class="text-muted">Avec l'application SmartCouture il est aujourd'hui possible de gérer sa clientelle sans papier. Toutes les informations clients sont enregistrées dans votre ordinateur ou votre smartphone.
                        </p>
                        <div class="text-muted mt-4">
                            <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Enregistrer vos clients</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>Avec leurs mesures</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center mt-5 pt-md-5">
                <div class="col-md-5">
                    <div class="mt-4 mt-md-0">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">02</div>
                            <h4 class="mb-0">Simplifiez la gestion de vos commandes</h4>
                        </div>
                        <p class="text-muted">SmartCouture vous permet de gérer à portée de clic votre répertoire clients, les commandes, les fiches de mesures, les références tissus, les délais de livraison et la facturation.</p>
                        <div class="text-muted mt-4">
                            <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Gerez les commandes clients</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>Avec des notifications SMS aux clients</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  col-sm-8 ms-md-auto">
                    <div class="mt-4 me-md-0">
                        <img src="assets/images/crypto/features-img/img-2.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>

            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- Features end -->


    <!-- Blog start -->
    <section class="section bg-white" id="news">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Blog</div>
                        <h4>Latest News</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row">
                <div class="col-xl-4 col-sm-6">
                    <div class="blog-box mb-4 mb-xl-0">
                        <div class="position-relative">
                            <img src="assets/images/landing/tailor.jpg" alt=""
                                class="rounded img-fluid mx-auto d-block">
                            <div class="badge bg-success blog-badge font-size-11">Mise a jour</div>
                        </div>

                        <div class="mt-4 text-muted">
                            <p class="mb-2"><i class="bx bx-calendar me-1"></i> 22 Mai, 2022</p>
                            <h5 class="mb-3">SmartCouture V2</h5>
                            <p>Une nouvelle version, un nouveau design</p>

                           
                        </div>
                    </div>
                </div>

                
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- Blog end -->

    <!-- Faqs start -->
    <section class="section" id="pricing">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Payez facilement en ligne</div>
                        <h4>Pricing</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row">
                <div *ngFor="let data of pricingData" class="col-xl-3 col-md-6">
                    <div class="card plan-box">
                      <div class="card-body p-4">
                        <div class="media">
                          <div class="media-body">
                            <h5>{{data.name}}</h5>
                            <p class="text-muted">{{data.text}}</p>
                          </div>
                          <div class="ms-3">
                            <i class="bx {{data.icon}} h1 text-primary"></i>
                          </div>
                        </div>
                        <div class="py-4">
                          <h2>
                            {{data.price}} 
                            <sup>
                                <small>fr cfa</small>
                            </sup>
                            /
                            <span class="font-size-13">mois</span>
                          </h2>
                        </div>
                        <div class="text-center plan-btn">
                          <a href="javascript: void(0);" class="btn btn-primary btn-sm position-relative">Sign up Now</a>
                        </div>
              
                        <div class="plan-features mt-5">
                          <p *ngFor="let f of data.features">
                            <i class="bx bx-checkbox-square text-primary mr-2"></i> {{f}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </section>
    <!-- Faqs end -->

    <!-- Footer start -->
    <footer class="landing-footer">
        <div class="container">

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Company</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="javascript: void(0);">A propos</a></li>
                            <li><a href="javascript: void(0);">Fonctionnalites</a></li>
                            <li><a href="javascript: void(0);">Actualites</a></li>
                            <li><a href="javascript: void(0);">FAQs</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Resources</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="javascript: void(0);">Whitepaper</a></li>
                            <li><a href="javascript: void(0);">Token sales</a></li>
                            <li><a href="javascript: void(0);">Privacy Policy</a></li>
                            <li><a href="javascript: void(0);">Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Links</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="javascript: void(0);">Tokens</a></li>
                            <li><a href="javascript: void(0);">FAQs</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Latest News</h5>
                        <div class="blog-post">
                            <a href="javascript: void(0);" class="post">
                                <div class="badge badge-soft-success font-size-11 mb-3">SmartCouture V2</div>
                                <h5 class="post-title">Une nouvelle version, un nouveau design</h5>
                                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 22 Mai, 2022</p>
                            </a>
                           

                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <hr class="footer-border my-5">

            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-4">
                        <img src="assets/images/logo-light-3.png" alt="" height="20">
                    </div>

                    <p class="mb-2">{{year}} © SmartCouture. Design & Develop by DefarSoft</p>
                    <p>Le Digital au service de la Couture</p>
                </div>

            </div>
        </div>
        <!-- end container -->
    </footer>
    <!-- Footer end -->

</div>