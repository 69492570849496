import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Commande } from 'src/app/core/models/commande.model';
import { CommandeService } from 'src/app/core/services/entities/commande.service';
import { ParametrageService } from 'src/app/core/services/entities/parametrage.service';
import { LoaderService } from 'src/app/core/services/loader.service';


@Component({
  selector: 'app-paiement-commande-modal',
  templateUrl: './paiement-commande-modal.component.html',
  styleUrls: ['./paiement-commande-modal.component.scss']
})
export class PaiementCommandeModalComponent implements OnInit {

  loading$ = this.loader.loading$;

  reliquatForm: FormGroup;
  @Input() selectedCommande: Commande;
  submit = false;
  @Output()
  statusChange : EventEmitter<string> = new EventEmitter<string>();

  @Output()
  retirerEvent : EventEmitter<number> = new EventEmitter<number>();

  @Output()
  commandeChange : EventEmitter<Commande> = new EventEmitter<Commande>();

  today = (new Date()).toISOString().substring(0,10);

  monnaie;

  constructor(public activeModal: NgbActiveModal, private service: CommandeService, public formBuilder: FormBuilder
    , private parametrageService: ParametrageService, private loader: LoaderService) { }

  ngOnInit(): void {

    this.submit = false;

    this.monnaie = this.parametrageService.getCurrency();

    this.reliquatForm = this.formBuilder.group({
      id: this.selectedCommande.id,
      paiementReliquat: [null, [Validators.required]],
      montantPaiement: 0,
      datePaiement: (new Date()).toISOString().substring(0,10),
      moyenPaiement: null,
    });

  }

  get form() {
    return this.reliquatForm.controls;
  }

  getReliquat(){ 
    let sommePayee = this.selectedCommande.paiements.reduce((sum, pay) => sum + pay.montant, 0)
    return this.selectedCommande.cout - sommePayee;
  }

  payment() {
    this.submit = true;

    this.reliquatForm.get('montantPaiement').setValidators([Validators.max(this.getReliquat())]);

    if (!this.reliquatForm.invalid) {

      let body = this.reliquatForm.value;

      this.loader.show();

      this.service.enregistrerPaiement(this.selectedCommande.id, body).subscribe(
        data => {

          console.log(data);

          this.commandeChange.emit(data);

          this.activeModal.dismiss();

          this.loader.hide();
          

        },
        error => {

          console.log(error);

          this.loader.hide();
        }
      );

    }
  }

}
