import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { Parametrage } from '../../models/parametrage.model';

@Injectable({ providedIn: 'root' })
export class ParametrageService {

    constructor(private http: HttpClient) { }

    get() {
        return this.http.get<Parametrage>(environment.BASE_URL + `/api/parametrage`);
    }

    toogleSMSEnabled() {
        return this.http.get<Parametrage>(environment.BASE_URL + `/api/parametrage/toogle-sms`);
    }

    set(parametrage: any) {
        return this.http.post<Parametrage>(environment.BASE_URL + `/api/parametrage`, parametrage);
    }

    setLogoLarge(body) {
        return this.http.post<Parametrage>(environment.BASE_URL + `/api/parametrage/logo`, body);
    }

    setLogoTransparent(body) {
        return this.http.post<Parametrage>(environment.BASE_URL + `/api/parametrage/logo-transparent`, body);
    }

    setCurrency(body) {
        return this.http.post<Parametrage>(environment.BASE_URL + `/api/parametrage/currency`, body);
    }

    getCurrency(){

        let cur = sessionStorage.getItem('currency');

        if(!cur){      
            this.get().subscribe(result =>{
                sessionStorage.setItem('currency', result.currency);
                return result.currency;
            }, error=>{
                return 'XOF';
            });
        }else{
            return cur;
        }
        
    }
}
