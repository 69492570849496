import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

   
  public  isLoading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this.isLoading.asObservable();
  count = 0;

  constructor() {}

  show() {
    this.count++;

    this.isLoading.next(true);
    
  }

  hide() {
    this.count--;

    //if(this.count<1){
      this.isLoading.next(false);
    //}
    
  }
  
}
