import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';

import { WidgetModule } from './widget/widget.module';
import { GetInitialPipe } from '../core/pipe/initial.pipe';
import { StatutCommandePipe } from '../core/pipe/statut-commande.pipe';

@NgModule({
  declarations: [GetInitialPipe, StatutCommandePipe],
  imports: [
    CommonModule,
    UIModule,
    WidgetModule
  ],
  exports:[GetInitialPipe, StatutCommandePipe]
})

export class SharedModule { }
